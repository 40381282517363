class Api {
  constructor ({address, headers}) {
    this._address = address;
    this._headers = headers;
  }

  _getResponseData(res) {
    if(res.ok) {
      return res.json()
     }else {
       return Promise.reject(res.status)
     } 
  }

  
  //получение данных о рецепте
  getSitemap() {
    return fetch(`${this._address}/sitemap.xml`, {
      method: 'GET',
      headers: this._headers, 
    })
    .then(this._getResponseData)
  }

   //получение данных о рецепте
   getUserActivity() {
    return fetch(`${this._address}/users/userActivity`, {
      method: 'GET',
      headers: this._headers, 
      credentials: 'include',
    })
    .then(this._getResponseData)
  }
  
  
  //Получение всех зарегистрированных пользователей
  getUsers () {
    return fetch(`${this._address}/users`, {
      method: 'GET',
      headers: this._headers,
      credentials: 'include',
    })
    .then(this._getResponseData)
    }

    //Получение  пользователей постранично
  getUsersPage (arr) {
    return fetch(`${this._address}/users/search?page=${arr.currentPage}&limit=${arr.limit}`, {
      method: 'GET',
      headers: this._headers,
      credentials: 'include',
    })
    .then(this._getResponseData)
    }

 //Удаление плана
 deleteOldPlan (date) {
  return fetch(`${this._address}/plan/oldplane/${date}`, {
    method: 'DELETE',
    headers: this._headers,
    credentials: "include",
    body: JSON.stringify()
  })
  .then(this._getResponseData)
}





                                              /* Администрирование */

 //Удаление пользователя админом
  deleteUser (userId, current) {
    return fetch(`${this._address}/users/me/admin/${userId}`, {
      method: 'DELETE',
      headers: this._headers,
      credentials: "include",
      body: JSON.stringify({current})
    })
    .then(this._getResponseData)
  }


  // Увеличение бонусов при принятии рецептов
  acceptRecipeUser(userId, bonus) {
    return fetch(`${this._address}/users/me/${userId}`, {
      method: 'PATCH',
      headers: this._headers,
      credentials: 'include',
      body: JSON.stringify({
        bonus
      })
    })
    .then(this._getResponseData)
  }

  //Изменение прав  админа
  editAdmin (admin, userId, current) {
    return fetch(`${this._address}/users/me/admin/${userId}`, {
      method: 'PATCH',
      headers: this._headers,
     credentials: "include",
      body: JSON.stringify({admin, current})
    })
    .then(this._getResponseData)
  }

  //Изменение прав Супервизора
  editVisor (supervisor, userId, current) {
    return fetch(`${this._address}/users/me/visor/${userId}`, {
      method: 'PATCH',
      headers: this._headers,
      credentials: "include",
      body: JSON.stringify({supervisor, current})
    })
    .then(this._getResponseData)
  }

  //Изменение доступа по подписке
  editAccces (acces, userId, current) {
    return fetch(`${this._address}/users/me/acces/${userId}`, {
      method: 'PATCH',
      headers: this._headers,
      credentials: "include",
      body: JSON.stringify({acces, current})
    })
    .then(this._getResponseData)
  }

  // Редактирование настроек пользователя
  updateuserSettings(data) {
    const settings = {
      sumFamily: data.capacity,
      periodAn: data.period,
      typeOfDiet: data.types,
      period: data.weekday,
      powerMode: data.meals,
      kitchenware: data.kitchenware,
      cookingTime: Number(data.cookingTime),
      stopProducts: data.ingredientsData,
    }
    return fetch(`${this._address}/users/me/settings`, {
      method: 'PATCH',
      headers: this._headers,
      credentials: 'include',
      body: JSON.stringify({settings})
  })
  .then(this._getResponseData)
}

  //Изменение данных пользователя(Супервизором)
  editUser (supervisor, bonus, subscriptions, dateAcces, finance, settings, id) {
    return fetch(`${this._address}/users/me/admin/update/${id}`, {
      method: 'PATCH',
      headers: this._headers,
      credentials: "include",
      body: JSON.stringify({supervisor, bonus, subscriptions, dateAcces, finance, settings
      })
    })
    .then(this._getResponseData)
  }
}


const api = new Api({
      //  address: 'http://localhost:3002',
  address: 'https://api.menubook.ru',
  headers: {
    'Content-Type': 'application/json'
}
});

export default api