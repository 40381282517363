
//import './App.css';
import React from 'react';
import { Route, Switch, useHistory,useLocation } from 'react-router-dom';
import {Ingredients, Main, Recipes, Users, ConstData, UserActiv, Test} from './component/pages';
import {Header, Footer, SideMenu, ModalInfo, NotFound } from './component/organisms';
import { errorMessage, authErrors, succesOk, infoMessage} from './constants/constants';
import * as auth from "./utils/auth.js";
import { ProtectedRoute } from './utils/ProtectedRoute'
import api from "./utils/api";
import {useDispatch} from 'react-redux'
import { getIngredients, getRecipe, getVitamins, getLibrary, getPlan, getUser, getUserActivity } from './store/api'
import Login from './component/pages/Login/Login.js';
import { CurrentUserContext } from "./contexts/CurrentUserContext";

function App() {
  const dispatch = useDispatch()
  const [loggedIn, setLoggedIn] = React.useState(false);
  const history = useHistory();
  const location = useLocation();
  const [showModal, setShowModal] = React.useState(false);
  const [textsucces, setTextsucces] = React.useState('');
  const [iconVisual,setIconVisual] = React.useState(false);
  const [currentUser, setCurrentUser] = React.useState({});
  const [users, setUsers] = React.useState([])

  //Получение токена при каждом мониторовании
  React.useEffect(() => {
    tokenCheck()
    dispatch(getLibrary())
    dispatch(getIngredients())
    dispatch(getUser())
    dispatch(getRecipe())
    //dispatch(getPlan())
    dispatch(getUserActivity())
  }, []);

  //Регистрация пользователя
  function onRegister( name, email, password ) {
    auth.register(name, email, password)
    .then((res) => {
      if(res){
        setShowModal(true);
        history.push('/signin');
      }
      setShowModal(true)
      setIconVisual(true)
      setTextsucces(succesOk.signinOk)
    })
    .catch(err => {
      if(err === authErrors.conflictErr) { 
        setShowModal(true)
        setIconVisual(false)
        setTextsucces(errorMessage.emailError)
      } else {
        setShowModal(true)
        setIconVisual(false)
        setTextsucces(errorMessage.registrError)
        
      }
    });
  }

//Вход в профиль
function onLogin(email,password){
  auth.authorize(email, password)
  .then(() => {
    tokenCheck();
  })
  .catch(err => { 
    if(err === authErrors.unauthorizedErr) { 
      setIconVisual(false)
      setShowModal(true)
      setTextsucces(errorMessage.emailandPasswordError)
      
    } else if(err === authErrors.forbiddenErr) {
      setIconVisual(false)
      setShowModal(true)
      setTextsucces(errorMessage.forbiddenErr) 
    } else {
      setIconVisual(false)
      setShowModal(true)
      setTextsucces(errorMessage.tokenError) 
    } 
  });
}

//Получение данных пользователя, email
function tokenCheck() {
  auth.getContent()
  .then((res) => {
    if(res){
      setCurrentUser(res);
      setLoggedIn(true)
     if (location.pathname === '/signin' || location.pathname === '/signup') {
        history.push('/');
      } else {
        history.push(location.pathname);
      }
    }
  })
  .catch(err => console.log(`Зарегистрируйтесь или войдите в систему: ${err}`))  
}

//Выход из системы
function onSignOut(){
  auth.signOut()
  .then(()=> {
    localStorage.clear();
    history.push('/signin');
    setLoggedIn(false)
  })
  .catch((err) => {
    if (err === authErrors.conflictErr) {
      setIconVisual(false);
      setShowModal(true);
      setTextsucces(`${errorMessage.logoutErr}:${err}`);
    } else {
      setIconVisual(false);
      setShowModal(true);
      setTextsucces(errorMessage.internalServerErr);
    }
  })
}

//функция добавления масссива инг в json формате
  function addNewaddIngredients (arr){
    arr.map((item)=> {
      api.createIngredientsArr(item)
      .then(() => {})
      .catch((err) => {
        if (err === authErrors.conflictErr) {
          setIconVisual(false);
          setShowModal(true);
          setTextsucces(errorMessage.conflictErr);
        } else {
          setIconVisual(false);
          setShowModal(true);
          setTextsucces(errorMessage.internalServerErr);
        }
      })
    })
  }

  //удаление юзера
  function handleDeleteUser(user) {
    api.deleteUser(user._id, currentUser.supervisor)
      .then(() => {
        setUsers((state)=> state.filter((c) => c._id !== user._id));
      })
      .catch((err) => {
        if (err === authErrors.notFoundErr) {
          setIconVisual(false);
          setShowModal(true);
          setTextsucces(errorMessage.userNotFound);
        } else if(err === authErrors.forbiddenErr) {
          setIconVisual(false);
          setShowModal(true);
          setTextsucces(errorMessage.forbiddenErrEdit);
        } else {
          setIconVisual(false);
          setShowModal(true);
          setTextsucces(errorMessage.internalServerErr);
        }
      })
  }

  //Изменение права доступа админа
  function handlerUserAdmin(data) {
    api.editAdmin(data.admin, data.user._id, currentUser.supervisor)
      .then((UpdateUser) => {
        setUsers((state) => state.map((c) => (c._id === data.user._id ? UpdateUser : c)));
      })
      .catch((err) => {
        if (err === authErrors.notFoundErr) {
          setIconVisual(false);
          setShowModal(true);
          setTextsucces(errorMessage.userNotFound);
        } else if(err === authErrors.forbiddenErr) {
          setIconVisual(false);
          setShowModal(true);
          setTextsucces(errorMessage.forbiddenErrEdit);
        } else {
          setIconVisual(false);
          setShowModal(true);
          setTextsucces(errorMessage.internalServerErr);
        }
      })
  }

  function handleUpdateUserSettings(data) {
    api.updateuserSettings(data)
    .then((res)=> {
      setCurrentUser(res)
      setShowModal(true)
      setIconVisual(true)
      setTextsucces(infoMessage.savedSetting)
    })
    .catch(err => { 
      if(err === authErrors.unauthorizedErr) { 
        setIconVisual(false)
        setShowModal(true)
        setTextsucces(errorMessage.emailandPasswordError)
        
      } else {
        setIconVisual(false)
        setShowModal(true)
        setTextsucces(errorMessage.internalServerErr) 
      } 
    }); 
  }

  //Изменение права доступа Модератора
  function handlerUserVisor(data) {
    api.editVisor(data.supervisor, data.id, currentUser.supervisor)
      .then((UpdateUser) => {
        setUsers((state) => state.map((c) => (c._id === data.id ? UpdateUser : c)));
      })
      .catch((err) => {
        if (err === authErrors.notFoundErr) {
          setIconVisual(false);
          setShowModal(true);
          setTextsucces(errorMessage.userNotFound);
        } else if(err === authErrors.forbiddenErr) {
          setIconVisual(false);
          setShowModal(true);
          setTextsucces(errorMessage.forbiddenErrEdit);
        } else {
          setIconVisual(false);
          setShowModal(true);
          setTextsucces(errorMessage.internalServerErr);
        }
      })
  }

  //Изменение права доступа Модератора
  function handleAccesUser(data) {
    api.editAccces(data.acces, data.id, currentUser.supervisor)
      .then((UpdateUser) => {
        setUsers((state) => state.map((c) => (c._id === data.id ? UpdateUser : c)));
      })
      .catch((err) => {
        if (err === authErrors.notFoundErr) {
          setIconVisual(false);
          setShowModal(true);
          setTextsucces(errorMessage.userNotFound);
        } else if(err === authErrors.forbiddenErr) {
            setIconVisual(false);
            setShowModal(true);
            setTextsucces(errorMessage.forbiddenErrEdit);
        } else {
            setIconVisual(false);
            setShowModal(true);
            setTextsucces(errorMessage.internalServerErr);
          }
      })
  }

  //Закрытие информационной модалки
  function handlerCloseError() {
    setShowModal(false);
  }

  //Прием рецептов и начисление бонусов
  function handleAcceptRecipe(id, bonus) {
    api.acceptRecipeUser(id, bonus)
      .then((UpdateUser) => {
        setUsers((state) => state.map((c) => (c._id === id ? UpdateUser : c)));
      })
      .catch((err) => {
        if (err === authErrors.notFoundErr) {
          setIconVisual(false);
          setShowModal(true);
          setTextsucces(errorMessage.userNotFound);
        } else if(err === authErrors.forbiddenErr) {
          setIconVisual(false);
          setShowModal(true);
          setTextsucces(errorMessage.forbiddenErrEdit);
        } else {
          setIconVisual(false);
          setShowModal(true);
          setTextsucces(errorMessage.internalServerErr);
        }
      })
  }

  //не принятие рецепта(не настроен пока)
  function handleNoAcceptRecipe(id, bonus) {
    api.acceptRecipeUser(id, bonus)
      .then((UpdateUser) => {
       
      })
      .catch((err) => {
        if (err === authErrors.notFoundErr) {
          setIconVisual(false);
          setShowModal(true);
          setTextsucces(errorMessage.userNotFound);
        } else if(err === authErrors.forbiddenErr) {
          setIconVisual(false);
          setShowModal(true);
          setTextsucces(errorMessage.forbiddenErrEdit);
        } else {
          setIconVisual(false);
          setShowModal(true);
          setTextsucces(errorMessage.internalServerErr);
        }
      })
  }

  //Изменение настроек пользователя(доступ бонусы и подписка)
  function handleEditDate(user) { 
    const { bonus, subscriptions, dateAcces, finance, id, settings } = user
    api.editUser(currentUser.supervisor, bonus, subscriptions, dateAcces, finance, settings, id)
      .then((UpdateUser) => {
        setUsers((state) => state.map((c) => (c._id === id ? UpdateUser : c)));
      })
      .catch((err) => {
        if (err === authErrors.notFoundErr) {
          setIconVisual(false);
          setShowModal(true);
          setTextsucces(errorMessage.userNotFound);
        } else if(err === authErrors.forbiddenErr) {
          setIconVisual(false);
          setShowModal(true);
          setTextsucces(errorMessage.forbiddenErrEdit);
        } else {
          setIconVisual(false);
          setShowModal(true);
          setTextsucces(errorMessage.internalServerErr);
        }
      })
  }

  return (
    <CurrentUserContext.Provider value={currentUser}>
    <div className="page">
      <Header loggedIn={loggedIn} onClick={onSignOut}/>
      <SideMenu loggedIn={loggedIn}/>
      <Switch>
        <Route exact path="/"> 
          <Main />
        </Route>
        <ProtectedRoute
          path="/data"
          component={ConstData} 
          loggedIn={loggedIn}
          //onClick={addNewaddIngredients}
         
        />
        <ProtectedRoute
          path="/ingredients"
          component={Ingredients} 
          loggedIn={loggedIn}
          onClick={addNewaddIngredients}
         
        />
        <ProtectedRoute
          path="/recipes"
          users={users}
          component={Recipes} 
          loggedIn={loggedIn}
          onAcceptRecipe={handleAcceptRecipe} 
          onNoAcceptRecipe={handleNoAcceptRecipe}
        />
        <ProtectedRoute
          path="/activ"
          users={users}
          component={UserActiv} 
          loggedIn={loggedIn} 
        />
        <ProtectedRoute
          path="/test"
          users={users}
          component={Test} 
          loggedIn={loggedIn} 
        />
         <ProtectedRoute
          path="/users"
          component={Users} 
          loggedIn={loggedIn}
          users={users}
          onDeleteUser={handleDeleteUser}
          onAdminUser={handlerUserAdmin}
          onAccesUser={handleAccesUser}
          onVisorUser={handlerUserVisor}
          onRegister={onRegister}
          onEditUserData={handleEditDate}
          onSubmit={handleUpdateUserSettings}
        />
        <Route path="/signin"> 
          <Login 
          onLogin={onLogin}
          loggedIn={loggedIn}
        />
        </Route>
        <Route path='*'>
          <NotFound />
        </Route>
      </Switch> 
      <Footer />
      <ModalInfo isOpen={showModal} textError={textsucces} onClose={handlerCloseError} icon={iconVisual} name="modal-info" />
    </div>
    </CurrentUserContext.Provider>
  );
}

export default App;
